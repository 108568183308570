<template>
  <div class="hello">
    <!-- 背景图片 -->
    <img src="@/assets/imgHome.jpg" class="full-screen-image" />

    <!-- For Users 鼠标悬停区域 -->
    <el-tooltip  :content="contentUser" placement="bottom-start" effect="customized" raw-content>
      <div class="clickable-area free-posting"></div>
    </el-tooltip>

    <!-- For Couriers 鼠标悬停区域 -->
    <el-tooltip :content="contentCourier" placement="bottom-end" effect="customized" raw-content>
      <div class="clickable-area trusted-vendors"></div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      contentUser: `<div style="background: linear-gradient(135deg, #cd1ac4, #6a00ea); color: white;padding-right: 20px;padding-top: 1px;padding-bottom: 1px">
                      <div>
                        <h1 style="font-size: 50px; font-family: 'Arial Narrow'; font-weight: normal">💡User Guide</h1>
                      </div>
                      <ul style="font-size: 26px;line-height: 1.8;">
                        <li>Enter addresses to check logistics fees!</li>
                        <li>Place orders online and schedule a pickup now!</li>
                      </ul>
                    </div>`,
      contentCourier: `<div style="background: linear-gradient(135deg, #cd1ac4, #6a00ea); color: white; padding-right: 20px;padding-top: 1px;padding-bottom: 1px">
                        <div>
                          <h1 style="font-size: 50px; font-family:'Arial Narrow'; font-weight: normal">💡Courier Overview</h1>
                        </div>
                        <ul style="font-size: 26px;line-height: 1.8;">
                          <li>Free registration, free access, and customized software adaptation.</li>
                          <li>We support API integration with company ERP systems and e-commerce <br>
                              platforms for one-click ordering, and offer weekly/monthly/quarterly<br>
                              billing options. (Free adjustments for app integration.)</li>
                        </ul>
                      </div>`,
    };
  }
};
</script>

<style scoped>
/* 全屏背景图片样式 */
.full-screen-image {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100vw;
  height: auto;
  object-fit: cover;
  position: absolute;
  left: 0;
  z-index: -1;
}

/* 通用点击区域样式 top: 0;border: 1px dashed rgba(0, 0, 0, 0.5);*/
.clickable-area {
  position: absolute;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.clickable-area:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Free Posting 区域样式 */
.free-posting {
  top: 37.5vh;
  left: 10.2vw;
  width: 27.5vw;
  height: 12vh;
}

/* Trusted Vendors 区域样式 */
.trusted-vendors {
  top: 36.5vh;
  left: 59.7vw;
  width: 27.5vw;
  height: 12vh;
}

/* 自定义tooltip样式 */
.el-tooltip__popper {
  background: linear-gradient(135deg, #cd1ac4, #6a00ea); /* 渐变紫色背景 */
  color: white; /* 文字颜色 */
  font-weight: bold; /* 加粗文字 */
  text-transform: uppercase; /* 文字大写 */
}

/* 你可以根据需要调整提示框的其他样式 */
.el-tooltip__popper .el-tooltip__content {
  /*padding: 10px 20px;  增加内边距 */
  font-size: 14px; /* 字体大小 */
}

.el-popper.is-customized {
  padding: 1px 1px;
  background: linear-gradient(90deg, #cd1ac4, #6a00ea);
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #cd1ac4, #6a00ea);
  right: 0;
}
</style>
